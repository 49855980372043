<template>
  <NavMenu v-if="isOrgValidated()" />
  <div class="app-wrapper">
    <router-view />
    <Footer />
  </div>
  <Loader v-show="loading" />
</template>

<script>
import NavMenu from "./components/NavMenu.vue";
import Footer from "./components/Footer.vue";
import Loader from "@/components/Loader.vue";
import { emitter } from '@/services/emitter.service';

export default {
  name: "App",
  components: {
    NavMenu,
    Footer,
    Loader  
  }, 
  data() {
    return { loading: false };
  },
  created() {
    emitter.on("showLoader", (e) => {
      this.loading = e;
    });
  },
  methods: {
    isOrgValidated() {
      return localStorage.getItem("org") != undefined;
    },
  },
};
</script>

<style>
:root {
    --primary-color: #800080;
    --highlight-color: #f4e6fb;
}

body {
  background-color: #F5F6FE;
}

.btn-danger,
.btn-danger:hover {
  color: white;
  background-color: crimson;
}

.app-btn-outline-primary {
    background: #fff;
    color: #800080;;
    border: 1px solid #800080;
}

.app-btn-outline-primary:hover {
    background: #800080;
    color: white;
    border: 1px solid #800080;
}

.app-btn-outline-danger {
    background: #fff;
    color: crimson;
    border: 1px solid crimson
}

.app-btn-outline-danger:hover {
    background: crimson;
    color: white;
    border: 1px solid crimson
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    transform: translate3d(0px, 3px, 0px) !important;
    position: relative !important;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: inherit;
  }
}
</style>