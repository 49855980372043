import auth0 from 'auth0-js'

const webAuth = new auth0.WebAuth({
    domain:       process.env.VUE_APP_AUTH0_DOMAIN,
    clientID:     process.env.VUE_APP_AUTH0_CLIENTID,
    responseType: 'token'
  });

export {
    webAuth
}