import { createWebHistory, createRouter } from "vue-router";
import { canActivate } from '@/services/auth-guard.service';
import { init } from '@/services/navbar.service';

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import('../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Callback",
    name: "Callback",
    component: () => import('../views/Callback.vue'),
  },
  {
    path: "/Init",
    name: "Init",
    component: () => import('../views/Init.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Counter",
    name: "Counter",
    component: () => import('../views/Counter.vue'),
  },
  {
    path: "/FetchData",
    name: "FetchData",
    component: () => import('../views/FetchData.vue'),
  },
  {
    path: "/Pages",
    name: "Pages",
    component: () => import('../views/Pages/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    },
  },
  {
    path: "/Pages/New",
    name: "New Page",
    component: () => import('../views/Pages/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Pages/Edit/:id",
    name: "Edit Page",
    component: () => import('../views/Pages/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Blogs",
    name: "Blogs",
    component: () => import('../views/Blogs/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Blogs/New",
    name: "New Blog",
    component: () => import('../views/Blogs/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Blogs/Edit/:id",
    name: "Edit Blog",
    component: () => import('../views/Blogs/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Categories",
    name: "Categories",
    component: () => import('../views/Categories/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Categories/New",
    name: "New Category",
    component: () => import('../views/Categories/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: "/Categories/Edit/:id",
    name: "Edit Category",
    component: () => import('../views/Categories/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings/Main.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/webhooks',
    name: 'Webhooks',
    component: () => import('../views/Webhooks/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/webhooks/new',
    name: 'New Webhook',
    component: () => import('../views/Webhooks/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/webhooks/edit/:id',
    name: 'Update Webhook',
    component: () => import('../views/Webhooks/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(async (to, from) => {
  if (from.path == to.path) return;

  window.scrollTo(0, 0);

  setTimeout(
    () => {
      init(to);
    },
    1000
  );
})

export default router;